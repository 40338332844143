import TimerIcon from '@mui/icons-material/Timer';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { ContentBlock, DraftDecorator } from 'draft-js';
import { ComponentProps, Fragment, FunctionComponent, ReactElement } from 'react';
import { withStyles } from 'tss-react/mui';

export enum SsmlTagType {
  LANGUAGE = 'En',
  SPELLING = 'Deutsch',
  PHONEMES = 'Phonem',
  PAUSE = 'Pause',
}

export const PAUSE_REGEX = (g: boolean) => new RegExp('<pause length="([^"]*)">', g ? 'g' : '');
export const LANG_REGEX = (g: boolean) =>
  new RegExp('<lang="en" (o="(nlp)"|o="(dict)" spelling="(.+?)")>(.+?)</lang>', g ? 'g' : '');
export const PHONEMES_REGEX = (g: boolean) => new RegExp('<phonemes="([^"]*)">([^<]+)</phonemes>', g ? 'g' : '');
export const SPELLING_REGEX = (g: boolean) => new RegExp('<spelling="([^"]*)">([^<]+)</spelling>', g ? 'g' : '');

// detectHighlightElementsByRegex applies given regex to the contentBlock and calls the callback
type DetectHighlightCmd = {
  regex: RegExp;
  contentBlock: ContentBlock;
  callback: (start: number, end: number) => void;
};
const detectHighlightElementsByRegex = ({ regex, contentBlock, callback }: DetectHighlightCmd) => {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
};

const HighlightTooltip: FunctionComponent<TooltipProps> = withStyles(Tooltip, {
  tooltip: {
    fontSize: '18px',
  },
});

/**
 * @description ReadOnlyHighlightComponent returns the read only component displaying the replacement with a tooltip for easy readability
 * */
type ReadOnlyHighlightComponentProps = {
  decoratedText: string;
  offsetKey: string;
  regex: RegExp;
  type: SsmlTagType;
};
export const ReadOnlyHighlightComponent = ({
  decoratedText,
  offsetKey,
  regex,
  type,
}: ReadOnlyHighlightComponentProps) => {
  const extracted = decoratedText.match(regex) || [];
  let replacement: string | JSX.Element = '';
  let tooltip: string | null = '';
  let cssClass = 'articleTextEditor-text--highlighted';

  switch (type) {
    case SsmlTagType.LANGUAGE: {
      /**
       * Regex: '<lang="en" (o="(nlp)"|o="(dict)" spelling="(.+?)")>(.+?)</lang>'
       * Example regex matches:
       * -----------------------------------------------------
       * 0: '<lang="en" o="dict" spelling="def">abc</lang>'
       * 1: 'o="nlp"' or 'o="dict" spelling="def"'
       * 2: 'nlp' or undefined
       * 3: 'dict' or undefined
       * 4: 'spelling' or undefined
       * 5: 'original'
       */

      const nlpOrigin: string | undefined = extracted[2];
      const dictOrigin: string | undefined = extracted[3];
      const spelling: string | undefined = extracted[4];
      const original: string = extracted[5];

      const origin: string | undefined = nlpOrigin ?? dictOrigin;

      replacement = spelling ?? original;
      tooltip = spelling ? `${type}: ${original}` : null;

      if (origin === 'nlp') {
        cssClass = cssClass + ' nlp-origin';
      }

      if (!origin) {
        cssClass = '';
      }
      break;
    }
    case SsmlTagType.SPELLING:
    case SsmlTagType.PHONEMES:
      replacement = extracted[1];
      tooltip = `${type}: ${extracted[2]}`;
      break;
    case SsmlTagType.PAUSE:
      replacement = <TimerIcon />;
      tooltip = `${type}: ${extracted[1]}`;
      cssClass = decoratedText.includes('o="nlp"') ? cssClass + ' nlp-origin' : cssClass;
      break;
    default:
      return <Fragment>decoratedText</Fragment>;
  }

  return tooltip ? (
    <HighlightTooltip title={tooltip} placement="top" arrow>
      <span className={`${cssClass}`} data-testid="highlighttext" data-offset-key={offsetKey}>
        {replacement}
      </span>
    </HighlightTooltip>
  ) : (
    <span className={`${cssClass}`} data-testid="highlighttext" data-offset-key={offsetKey}>
      {replacement}
    </span>
  );
};

// EditingHighlightComponent returns the edit mode component displaying the original ssml tag highlighted or easy editing
type EditingHighlightComponentProps = {
  offsetKey: string;
  type: SsmlTagType;
  children: string | ReactElement[];
};
export const EditingHighlightComponent = ({ offsetKey, type, children }: EditingHighlightComponentProps) => {
  if (!type) {
    throw new Error('type must be passed "LANGUAGE" | "PAUSE" | "SPELLING" | "PHONEMES"');
  }
  let cssClass = 'articleTextEditor-text--highlighted';
  if (type === SsmlTagType.LANGUAGE) {
    // In a test we pass string to the component
    // But in the actual implementation we pass a react component
    const text = typeof children === 'string' ? children : children[0].props.text;
    cssClass = text.includes('o="nlp"') ? cssClass + ' nlp-origin' : cssClass;
  }

  return (
    <span className={`${cssClass}`} data-testid="highlighttext" data-offset-key={offsetKey}>
      {children}
    </span>
  );
};

// editModeHighlightComponentGenerator returns a React component
const editModeHighlightComponentGenerator = (type: SsmlTagType) => (props: ComponentProps<any>) =>
  <EditingHighlightComponent {...props} type={type} />;

/**
 * @description Higher Order Component (HOC) that creates `ReadOnlyHighlightComponent` for every SSML tag
 * @param regex RegExp
 * @param type LANGUAGE | PAUSE | SPELLING | PHONEMES
 * */
const readOnlyModeHighlightComponentGenerator = (regex: RegExp, type: SsmlTagType) => (props: ComponentProps<any>) =>
  <ReadOnlyHighlightComponent {...props} regex={regex} type={type} />;

/**
 * @description returns a draft-js decorator consisting of a strategy and a component
 */
const generateDecorator = (regex: RegExp, component: FunctionComponent): DraftDecorator => {
  const strategy = (contentBlock: ContentBlock, callback: DetectHighlightCmd['callback']) =>
    detectHighlightElementsByRegex({
      regex,
      contentBlock,
      callback,
    });
  return {
    strategy: strategy,
    component: component,
  };
};

export const editingDecorators: DraftDecorator[] = [
  generateDecorator(LANG_REGEX(true), editModeHighlightComponentGenerator(SsmlTagType.LANGUAGE)),
  generateDecorator(PAUSE_REGEX(true), editModeHighlightComponentGenerator(SsmlTagType.PAUSE)),
  generateDecorator(SPELLING_REGEX(true), editModeHighlightComponentGenerator(SsmlTagType.SPELLING)),
  generateDecorator(PHONEMES_REGEX(true), editModeHighlightComponentGenerator(SsmlTagType.PHONEMES)),
];

export const readonlyDecorators: DraftDecorator[] = [
  generateDecorator(LANG_REGEX(true), readOnlyModeHighlightComponentGenerator(LANG_REGEX(false), SsmlTagType.LANGUAGE)),
  generateDecorator(PAUSE_REGEX(true), readOnlyModeHighlightComponentGenerator(PAUSE_REGEX(false), SsmlTagType.PAUSE)),
  generateDecorator(
    SPELLING_REGEX(true),
    readOnlyModeHighlightComponentGenerator(SPELLING_REGEX(false), SsmlTagType.SPELLING)
  ),
  generateDecorator(
    PHONEMES_REGEX(true),
    readOnlyModeHighlightComponentGenerator(PHONEMES_REGEX(false), SsmlTagType.PHONEMES)
  ),
];
