export enum DictionaryEntryType {
  PHONEME = 'PHONEME',
  SPELLING = 'SPELLING',
  ENGLISH = 'ENGLISH',
}

export enum TypeLabels {
  ENGLISH = 'Englisch',
  SPELLING = 'Deutsch',
  PHONEME = 'Phonem',
}

export interface DictionaryEntry {
  adaption: string;
  createdAt: string;
  original: string;
  type: DictionaryEntryType;
  updatedAt: string;
}
